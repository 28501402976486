.markdown-view {
    // For some reason the default bullet behaviour is altered
    // This restores that
    ul, ol {
        color: black;
        padding-left: 1rem;
    }
    ul {
        list-style: unset;
    }
    p {
        padding-bottom: 1rem;
    }
}