@import "style/index.scss";

.button {
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: white;
  padding: 0.5rem 1rem;
  height: 50px;
  text-decoration: none;
  background: $color-primary;
  color: white;
  font-size: 18px;
  font-weight: 400;

  &.inline {
    margin-bottom: 1rem;
  }

  &.anchored {
    position: fixed;
    bottom: 2rem;
    left: 2rem;
    right: 2rem;
  }

  &.white {
    background-color: white;
    color: $color-primary;
  }

  &.secondary {
    background-color: unset;
    border: 2px solid $color-primary;
    color: $color-primary;

    &.white {
      border: 2px solid white;
      color: white;
    }
  }

  &.spaced-right {
    margin-right: 1rem;
  }

  &:hover {
    cursor: pointer;
  }

  img {
    margin-left: 1rem;
    width: 20px;
    height: 20px;
  }

  &:hover {
    opacity: 0.9;
  }

}