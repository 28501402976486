
@font-face {
  font-family: "Roboto";
  font-weight: 300;
  src: url('/fonts/Roboto-Light.ttf')format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 400;
  src: url('/fonts/Roboto-Regular.ttf') format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  src: url('/fonts/Roboto-Medium.ttf') format("truetype");
}
