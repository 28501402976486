@import "style/index.scss";


.button-anchor {
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  height: 50px;
  text-decoration: none;
  font-size: 16px;
  color: $color-primary;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;

  &.inline {
    margin-bottom: 1rem;
  }
  &.spaced-right {
    margin-right: 1rem;
  }

  &:hover {
    cursor: pointer;
  }

  img {
    margin-left: 1rem;
    width: 20px;
    height: 20px;
  }

  &:hover {
    opacity: 0.9;
  }

}
