@import "style/index.scss";



html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  background-color: white;
  color: #333;
  font-family: "Roboto" !important;
  font-weight: 300;
  margin: 0;
  padding: 0;
}

p,
input,
label {
  font-weight: 300;
}

#root {
  height: 100%;
  max-width: 100vw;
}

*:focus {
  outline: none;
}

.App {
  flex: 1;
  height: 100%;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;

  @include tablet-up {
    position: fixed;
    height: 800px;
    width: 420px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: solid 1px #ccc;
    overflow: hidden;
    border-radius: 16px;
  }
}


h1,
h2,
h3,
h4 {
  font-weight: 500;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 20px;
  margin-bottom: 1rem;
}

h3 {
  font-size: 20px;
  margin-bottom: 0.5rem;
}

span,
p {
  font-size: 17px;
}

.stats {
  display: flex;
  align-items: center;

  span {
    margin-left: 0.5rem;
  }
}

.cols {
  display: flex;
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  .col {
    flex: 1;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.divider {
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 1px;
  width: 100%;
  background-color: #ddd;
}

.container-content {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 0;

}

.container-tabs {
  display: flex;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0 -2px 5px -2px #444;
  z-index: 1;
  .tab-group {
    .MuiButtonBase-root  {
      padding-left: 8px;
      padding-right: 8px;
    }
    display: flex;
    justify-content: space-between;
  }
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


input:-webkit-autofill {
  -webkit-background-clip: text;
}

.button-icon.MuiIconButton-root {
  padding: 1rem !important;
}