@import "style/index.scss";

.recipe-chosen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: white;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
  z-index: 100;
  overflow-y: auto;

  h2 {
    font-size: 28px;
    font-weight: 300;
  }

  &.closing {
    animation: fadeOut 0.5s forwards;
  }

  .button-close {
    width: 16px;
    height: 16px;
  }

  .close {
    content: 'x'; // here is your X(cross) sign.
    color: #333;
    font-weight: 300;
    font-family: Arial, sans-serif;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 50%;
  }

  .container-top {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    height: 50%;
    width: 100%;
    color: white;
    padding: 1rem;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  }

  .button-favorite {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    color: white;
  }

  .container-content {
    padding: 1rem;
  }

  p {
    color: #333;
  }
}