.profile {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 1rem;
  padding-top: 2rem;
  overflow: auto;
  flex: 1;

  .username {
    font-size: 28px;
    margin-top: 1rem;
    font-weight: 300;
    margin-left: auto;
    margin-right: auto;
  }
}