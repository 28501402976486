@import "style/index.scss";

.screen-login {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  &:before {
    background-image: url("/assets/img/food-background.jpg");
    filter: blur(2px) brightness(70%);
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 0;
  }
  .container-logo {
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;

    z-index: 1;
    .logo {
      .logo-header {
        color: white;
        font-size: 52px;
        max-width: 100px;
        line-height: 48px;
      }

      .logo-image {
        width: 128px;
        height: 128px;
      }
    }
  }


  .container-form {
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    padding-bottom: 2rem;
    margin: 1rem;
    background-color: rgba(255,255,255,0.9);
    border-radius: 16px;
  }

  svg {
    fill: $color-primary
  }

}