@import "style/index.scss";

.screen-register {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  background-image: url("/assets/img/food-background.jpg");

  .container-logo {
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;

    .logo {
      .logo-header {
        color: white;
        font-size: 52px;
        max-width: 100px;
        line-height: 48px;
      }

      .logo-image {
        width: 128px;
        height: 128px;
      }
    }
  }

  .container-form {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    padding-bottom: 2rem;
    margin: 1rem;
    background-color: rgba(255,255,255,0.9);
    border-radius: 16px;
  }

  svg {
    fill: $color-primary
  }

}