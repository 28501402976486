.input-markdown {
    .ck-editor__editable_inline {
        min-height: 100%;
    }
    ul, ol {
        color: black;
        padding-left: 1rem;
    }
    ul {
        list-style: unset;
    }
    margin-bottom: 1rem;

    .ck-toolbar_grouping {
        border-top-left-radius: 4px !important;
        border-top-right-radius: 4px !important;
    }
    .ck-editor__editable {
        border-bottom-right-radius: 4px !important;
        border-bottom-left-radius: 4px!important;
    }
}
