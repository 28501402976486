@import "style/index.scss";

.page-landing {
  .h1-landing {
    font-size: 42px;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 32px;
  }

  .logo {
    height: 128px;
  }

  p {
    font-size: 20px;
  }

  .title-content {
    max-width: 480px;
  }

  .card-previews {
    position: relative;

    .swiper--card {
      position: absolute;

      &.c1 {
        transform: rotate(10deg);
        margin-left: 128px;
        @include mobile {
          margin-left: 72px;
        }
      }

      &.c2 {
        transform: rotate(20deg);
        margin-left: 256px;
        @include mobile {
          margin-left: 128px;
        }
      }
    }

  }

  .container-top {
    min-height: 40vh;
    color: white;
    background: linear-gradient(to right, $color-primary, $color-primary-end 100%);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    z-index: 100;
    position: relative;

    @include tablet-down {
      flex-direction: column;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  .container-left,
  .container-right {
    flex: 1;
  }

  .container-left {
    display: flex;
    margin-top: 2rem;
    justify-content: center;

  }

  .container-right {
    height: 150px;
    @include tablet-down {
      margin-top: 6rem;
      margin-bottom: 4rem;
      width: 480px;
      height: 600px;
    }
    @include mobile {
      width: 100%;
    }
  }

  .container-middle {
    position: absolute;
    width: 100%;
    margin-top: -10vw;
    z-index: -1;

    svg {
      stroke: none;
      // fill: $color-primary;
      fill: url(#primary-gradient);

    }
  }

  .container-bottom {
    margin: auto;
    margin-top: 300px;
    max-width: 720px;

    @include mobile {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
}