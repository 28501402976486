@import "style/index.scss";

.header {
  display: flex;
  align-items: center;
  background: $color-primary;
  font-weight: 500;
  z-index: 1;
  min-height: 55px;
  max-height: 55px;

  
  .back {
    position: absolute;
    left: 0;
  }
  .action {
    position: absolute;
    right: 0;
    color: $color-primary;
  }
  .title {
    padding: 1rem;
    font-weight: 400;
    color: white;
    font-size: 24px;
  }
}