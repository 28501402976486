@import "style/index.scss";


.photos-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.recipe-card-form {

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  z-index: 2;
  animation: fadeInRight 0.3s forwards;

  .button-anchor {
    margin-top: 1rem;
  }

  .form-content {
    padding: 1rem;
  }

  .container-form {
    flex: 1;
    position: relative;
    transition: transform 0.8s;
  }


  .card-side {
    display: flex;
    padding: 2rem;
  }

  .container-about {
    display: flex;
    align-items: center;
  }

  .container-about-2nd {
    display: flex;
    align-items: center;
    margin-top: -24px;
  }

  .sub {
    font-size: 32px;
    font-weight: 500;
    color: #333;
  }

  .detail-side {
    transform: rotateY(180deg);
    padding: 1rem;
    padding-top: 2rem;

  }

  .container-recipe-name {
    position: relative;

    .icon-edit {
      position: absolute;
      right: 0;
      bottom: 30px;
      color: white;
    }
  }

  &.hiding {
    animation: fadeOutLeft 0.3s forwards;
  }

  .form-container {
    flex: 1;

  }

  .container-card-centered {
    width: 100%;
  }

  .container-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: #444;
    width: 90%;
    height: 0;
    padding-top: 150%;
    position: relative;
    margin: auto;
    margin-top: 1rem;
    border-radius: 8px;
  }


  .recipe-image-info {
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
  }

  .container-image {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    margin-bottom: 1rem;
    border-radius: 8px;

    .img-recipe {
      position: absolute;
      object-fit: cover;
      overflow: hidden;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      border-radius: 8px;
    }

    .image-info {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 1rem;
      text-align: center;
      color: white;
      background-color: darkgrey;

    }
  }


  .content-bottom {
    position: absolute;
    bottom: 0;
    z-index: 2;
    padding: 1rem;
    padding-bottom: 2rem;
    padding-top: 2rem;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));
    width: 100%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .input-recipe-name {
    border: none;
    border-bottom: 2px solid #333;
    caret-color: $color-primary;
    font-size: 32px;
    color: $color-primary;
    font-weight: 500;
    background-color: transparent;
    height: 50px;
    width: 100%;
    margin-bottom: 1rem;
    font-family: "Roboto";
    word-break: break-word;
    resize: both;

    &::placeholder {
      color: rgba(255, 255, 255, 0.4);
    }

  }

  .second {
    .input-stepper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  .hidden-file {
    display: none;
  }

  .image-change {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -8px;
    right: -8px;
    width: 32px;
    height: 32px;
    background-color: white;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1), 0 10px 30px 0 rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    cursor: pointer;
    z-index: 1;

    &:hover {
      background-color: lightgray;
      color: white;
    }
  }
}


@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(300px);
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    transform: translateX(300px);
    opacity: 0;
  }
}