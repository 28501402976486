@import "style/index.scss";

.profile-image-editable {
  width: 128px;
  height: 128px;
  border-radius: 64px;
  margin-left: auto;
  margin-right: auto;
  border: solid 3px $color-primary;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
  position: relative;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 50%;
  }
  .profile-placeholder {
    color: #fff;
  }
  .hidden-file {
    display: none;
  }
  .image-change {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -4px;
    right: -4px;
    width: 32px;
    height: 32px;

    background-color: white;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1), 0 10px 30px 0 rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    cursor: pointer;
    z-index: 1;

    &:hover {
      background-color: $color-primary;
      color: white;
    }
  }
}