@import "style/index.scss";


*,
*:before,
*:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.button-filter {
  position: fixed;
  color: $color-primary;
  top: .5rem;
  right: .5rem;
}

.swiper {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: opacity 0.1s ease-in-out;
  height: calc(100% - 50px);
  justify-content: center;
  align-items: center;
  margin: 2rem 1rem;
}

.loaded.swiper {
  opacity: 1;
}
