@import "style/index.scss";


.photo-item {
  width: 80px;
  height: 80px;
  border-radius: 16px;

  background-color: white;
  margin: 0.5rem;

  .photo-item-image {
    width: 80px;
    height: 80px;
    border-radius: 16px;
  }


  &.empty {
    border: dashed 3px #aaa;
  }

  &.add {
    border: unset;
    background-color: #ddd;
  }
}