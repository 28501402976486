.recipe-list {
    flex-direction: column;
    position: relative;
    padding: 1rem;
    padding-top: 1rem;
    flex: 1;
    display:grid;
    grid-template-columns: calc(50vw - 2rem) calc(50vw - 1rem);
    grid-row: auto auto;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
}

.container-empty {
    flex: 1;
    justify-content: center;
    align-items: center;
}

