
@import "style/index.scss";

.button-add {
  position: absolute;
  right: 1rem;
  bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 56px;
  height: 56px;
  border-radius: 32px;
  background-color: $color-primary;
  box-shadow: 0px 0 0 #333, 0px -1px 7px #aaa;
  z-index: 2;

  .icon-add {
    width: 32px;
    height: 32px;
  }
  &:hover {
    opacity: 0.9;
  }

}
