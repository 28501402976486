@import "style/index.scss";

.info-item {
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:nth-child(2n) {
    flex-direction: row-reverse;

    .container-circle-icon {
      margin-right: 0;
      margin-left: 2rem;
      @include mobile {
        margin-left: 0;
      }
    }

  }

  opacity: 0;
  animation: fadeIn ease 0.8s forwards;

  @include mobile {
    flex-direction: column !important;
  }

  .block-info {
    flex: 5 1;
    text-align: left;

    @include mobile {
      text-align: center;
    }

    h3 {
      font-size: 28px;
      margin-bottom: 0.5rem;
    }


  }

  .img-info {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 64px;
    max-height: 64px;
    width: 75%;
    height: 75%;
    font-size: 40px;
    font-weight: 500;
    color: white;
  }

  .container-circle-icon {
    background-image: linear-gradient(45deg, $color-primary 0%, $color-primary-end 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    margin-right: 2rem;
    @include mobile {
      margin-right: 0;
    }
  }

}