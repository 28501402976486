@import "style/index.scss";

.input-stepper {

  display: inline;

  .label {
    color: #333;
    flex: 1;
    font-size: 32px;
    font-weight: 500;
  }

  .value {
    color: $color-primary;
    text-align: center;
    font-size: 32px;
    font-weight: 500;
    padding: .5rem;
  }

  .MuiButtonBase-root {
    padding: 0;
  }

  .actions {
    display: flex;
    flex-direction: column;
  }

  .stepper-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    color: $color-primary;
    background-color: rgba(255, 255, 255, 0.1);
  }

  .container-stepper {
    display: inline-flex;
    color: white;
    align-items: center;
    // background-color: rgba(255,255,255,0.7);
    border-radius: 25px;
  }
}