.container-multi-select {
    margin-bottom: 1rem;
    .container-chips {
        display: flex;
        flex-wrap: wrap;
    
        .MuiChip-root {
          margin-right: 0.5rem;
          margin-bottom: 0.5rem;
        }
        .MuiChip-label {
          font-size: 15px;
        }
      }
}