.modal-background {
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.5s forwards;
    z-index: 1;
    .popup {
        background-color: white;
        padding: 1rem;
        border-radius: 8px;
        flex: 1;
        margin: 2rem;
    }
}

