.cards-filter {
  position: fixed;
  background-color: rgba(0,0,0,0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  display: flex;
  animation: fadeIn 0.5s forwards;
  .container-form {
    flex: 1;
    background-color: white;
    padding: 1rem;
    margin: 1rem;
    border-radius: 8px;
    min-height: 50vh;
  }

}