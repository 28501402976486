@import "style/index.scss";

.swiper--card {
  display: inline-block;

  border-radius: 8px;
  overflow: hidden;
  position: absolute;
  will-change: transform;
  transition: all 0.3s ease-in-out;
  cursor: grab;
  opacity: 1 !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  height: 100%;
  width: 100%;

  @include tablet-up {
    width: 332px;
    height: 512px;
  }

  .label {
    font-size: 28px;
    font-weight: 300;
    color: white;
  }

  &.img {
    max-width: 100%;
    pointer-events: none;
  }

  &.p {
    margin-top: 24px;
    font-size: 20px;
    padding: 0 16px;
    pointer-events: none;
  }

  &.h3 {
    margin-top: 32px;
    font-size: 32px;
    padding: 0 16px;
    pointer-events: none;
  }

  .card-icon {
    width: 24px;
    height: 24px;
  }

  .content {
    .content-bottom {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 9;
      height: 250px;
      padding: 1rem;
      padding-bottom: 2rem;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));

      .stats {
        color: white;
        font-weight: 400;
      }
    }

    .image {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

}

.moving.swiper--card {
  transition: none;
  cursor: grabbing;
}