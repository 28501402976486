@import "style/index.scss";

.swiper--card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  position: relative;

  &.preview {
    width: 270px;
    height: 400px;
    transition: all .8s ease-out !important;


    .content {
      .content-bottom {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9;
        height: 250px;
        padding: 1rem;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));

        h2 {
          font-size: 28px;
          color: white;
        }

        .stats {
          color: white;
        }
      }

      .image {
        object-fit: cover;
        width: 100%;
        height: 107%;
      }
    }



    @include tablet-down {
      width: 170px;
      height: 270px;

      .content {
        .content-bottom {
          h2 {
            font-size: 20px;
          }

          .stats {
            font-size: 13px;
          }
        }
      }
    }

    @include mobile {
      width: 100;
      height: 180;
    }
  }

}