@import "style/index.scss";

.app-header {
  color: white;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(to right, $color-primary, $color-primary-end 100%);
  z-index: 2;


  .header-logo {
    margin-left: 2rem;
    padding-top: 2rem;

    display: flex;
    align-items: center;

    .title {
      margin-left: 0.5rem;
      width: 50px;
      font-size: 32px;
      line-height: 28px;
      font-weight: 500;
      color: white;
    }
  }


  .container-menu-mobile {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: flex;
    flex-direction: column;
    background: linear-gradient(to right, $color-primary, $color-primary-end 100%);
    width: 100%;
    padding-top: 2rem;

    .menu-button,
    .menu-button-outlined {
      margin-bottom: 1rem;
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  .menu-button-mobile {
    margin-right: 2rem;
    margin-top: 2rem;

    @include desktop {
      display: none;
    }
  }

  .container-menu-desktop {
    padding-top: 2rem;
    margin-right: 2rem;

    display: flex;

    @include tablet-down {
      display: none;
    }
  }

  .menu-buttons {
    margin-left: 3rem;
  }

  a {
    text-decoration: none;
  }

  .menu-link {
    text-decoration: none;
    font-size: 20px;
    transition: font-size 0.5s;
    margin-left: 1.5rem;
    color: white;
    border-top: solid transparent 3px;

    &:hover {
      border-top: solid #fff 3px;
      padding-top: 10px;
      opacity: 1;
    }
  }

  .menu-button {
    background-color: white;
    color: #333;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    border-top: unset;

    &:hover {
      opacity: 0.8;
      border-top: unset;
    }
  }

  .menu-button-outlined {
    border: solid 2px white;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    margin-left: 1rem;

    &:hover {
      opacity: 0.8;
    }
  }

  .app-logo {
    width: 64px;
    height: 64px;
  }

}

.white {
  .img-mobile-connect {
    filter: brightness(0%)
  }

  .container-menu-mobile {
    background: white;
  }

  .app-header {
    background: white;
    border-bottom: solid 1px rgba(0, 0, 0, 0.2);

    .menu-button-mobile {
      color: #333;
    }

    .menu-button-outlined {
      color: #333;
      border: solid 2px #333;
    }

    .menu-button {
      background-color: $color-secondary;
      color: white;

    }
  }
}