@import "style/index.scss";

.profile-image-top {
  position: fixed;
  left: 1rem;
  top: .75rem;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;

  img {
    object-fit: cover;
    overflow: hidden;
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }

  .profile-placeholder {
    color: #fff;
  }

}