@import "style/index.scss";

.recipe-item {
    display: flex;
    background-color: white;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    height: 35vh;

    .title {
        z-index: 1;
        flex: 1;
        font-weight: 400;
        position: absolute;
        bottom: 1rem;
        color:white;
    }

    .content {
        z-index: 1;
        display: flex;
        flex: 1;
        margin-left: 1rem;
    }

    .divider {
        width: 1px;
        height: 16px;
        background-color: lightslategrey;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .background-image {
        position: absolute;
        top:0;
        left: 0;
        right: 0;
        bottom: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}