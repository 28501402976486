@import "style/index.scss";

.friend-item {
  display: flex;
  flex: 1;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  position: relative;
  background-color: white;
  border-radius: 4px;
  padding: 1rem;

  .profile-image {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ddd;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .username {
    font-weight: 400;
    padding-right: 1rem;
  }

  .status {
    background-color: #ddd;
    padding: 0.25rem 0.5rem;
    border-radius: 20px;
    margin-right: 1rem;
    font-size: 14px;
  }

  .content {
    display: flex;
    flex: 1;
    margin-left: 1rem;
    align-items: center;
    justify-content: space-between;
  }

  .container-end {
    display: flex;
    align-items: center;
  }

  .actions {
    display: flex;
    color: #ccc;
  }

  .handle-request {
    .decline {
      color: #333;
    }

    .accept {
      margin-left: 1rem;
      color: #333;
    }

  }

  .divider {
    width: 1px;
    height: 16px;
    background-color: lightslategrey;
    margin-left: 1rem;
    margin-right: 1rem;
  }

}